#PortfolioTableId td:nth-child(4),
#PortfolioTableId td:nth-child(6),
#PortfolioTableId td:nth-child(2),
#PortfolioTableId td:nth-child(3){
    text-align: left;
}
#PortfolioTableId td:nth-child(1),
#PortfolioTableId td:nth-child(5){
    text-align: center;
}
#PortfolioTableId th:nth-child(6){
    text-align: center;
}
#PortfolioTableId td:nth-child(1){
    width: 3px;
}