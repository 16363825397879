#allTaskListTable th{
    text-align: center;
}
#allTaskListTable td:nth-child(1){
    width:250px
}
#allTaskListTable td:nth-child(2){
    width:200px
}
#allTaskListTable td:nth-child(3){
    width:100px
}
#allTaskListTable td:nth-child(4),
#allTaskListTable td:nth-child(5){
    width:50px
}
#allTaskListTable td:nth-child(6),
#allTaskListTable td:nth-child(7),
#allTaskListTable td:nth-child(8){
    width:100px
}
#allTaskListTable td, #allTaskListTable th{
    padding: 7px !important;
}
#allTaskListTable td:nth-child(9){
    width:45px
}
#allTaskListTable .chat-icon i{
    top: 17px;
    position: absolute;
    right: 11px;
  }
#allTaskListTable  .chat-icon .badge {
    position: absolute;
    top: 8px;
    right: 0px;
    background: red;
    color: white;
    font-size: 8px;
  }
#tasktimeline td:nth-child(1),
#tasktimeline td:nth-child(2),
#tasktimeline td:nth-child(3),
#tasktimeline td:nth-child(4){
  text-align: center;
}
#tasktimeline th{
    text-align: center;
} 
#tasktimeline td:last-child{
  text-align: left;
} 