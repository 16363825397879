#cronjobactivityId th:nth-child(1){
    width: 80px;
}
#cronjobactivityId th:nth-child(2){

    width: 150px;
}
#cronjobactivityId th:nth-child(3){
    width: 100px;
}
#cronjobactivityId th:nth-child(5){
    width: 150px;
}
#cronjobactivityId td:nth-child(1){
    text-align: center;
}
#cronjobactivityId td:nth-child(2){
    text-align: center;
}
#cronjobactivityId td:nth-child(3){
    text-align: center;
}
#cronjobactivityId td:nth-child(4){
    text-align: center;
}
// #cronjobactivityId td:nth-child(5){
//     text-align: center;
// }
#cronjobactivityId td:nth-child(7){
    text-align: left;
}
