#main-search .select2-selection__placeholder {
    margin-left: 16px;
}

#main-search .select2-selection__indicator {
    display: none;
}

#main-search .select2-selection__input,
#main-search .select2-selection__single-value {
    padding-left: 14px;
}
.projectOptions{
    color: black;
}
.clientOptions{
    color: rgb(241, 10, 10);
}