#table_fund_transfer td:nth-child(5){
    text-align: right;
  }
#table_fund_transfer td:nth-child(7){
    text-align: left;
  }
  #table_fund_transfer th{
    text-align: center;
  }
  #table_fund_transfer td:nth-child(1),
  #table_fund_transfer td:nth-child(2){
    text-align: center;
  }