#SupportTicketTableID th {
    text-align: center;
}
#SupportTicketTableID td:nth-child(1),
#SupportTicketTableID td:nth-child(2),
#SupportTicketTableID td:nth-child(7),
#SupportTicketTableID td:nth-child(8) {
  text-align: center;
}
.dropdownAuto {
  background-color: white;
  max-height: 200px;
  overflow: auto;
  box-shadow: rgba(100,100,111,0.2) 0px 7px 29px 0px;
}
.dropdownItem {
  padding: 7px 10px;
  cursor: pointer;
}
.dropdownItem:hover {
background-color: #d5e2ee;
transition: 0.2s
}
.dropdownAuto::-webkit-scrollbar {
  width: 5px;
} 
.dropdownAuto::-webkit-scrollbar-thumb {
  background: lightgrey;
}
.dropdownAuto::-webkit-scrollbar-thumb:hover {
  background: grey;
}
#taskListTable > :not(caption) > * > *{
  padding: 4px;
}
#taskListTable th{
  padding: 8px;
}
#taskListTable td .form-control{
  height: 30px !important;
}
#taskListTable td .select2-selection__control {
  height: 30px !important;
  min-height: 30px !important;
}
#taskListTable td .select2-selection__indicator {
  padding: 5px !important;
}
#allTaskListTables td, #allTaskListTables th{
  padding: 7px !important;
}
#allTaskListTables .chat-icon i{
  top: 17px;
  position: absolute;
  right: 11px;
}
#allTaskListTables  .chat-icon .badge {
  position: absolute;
  top: 8px;
  right: 0px;
  background: red;
  color: white;
  font-size: 8px;
}
#support_view td{
  border: white thick solid;
}
#supportfollowupModalTable th{
  text-align: center;
}
#supportfollowupModalTable td:nth-child(1),
#supportfollowupModalTable td:nth-child(2),
#supportfollowupModalTable td:nth-child(3),
#supportfollowupModalTable td:nth-child(5){
  text-align: center;
  width: 100px;
}
#supportfollowupModalTable td:last-child{
  text-align: left;
}