.kanboard{
    display: flex;
      width: 100%;
      grid-gap: 15px;
      gap: 10px;
      overflow: auto;
    
      white-space: nowrap;
        padding-bottom: 20px;
  }
  ::-webkit-scrollbar {
    width: 20px;
    height: 10px;
  }
  ::-webkit-scrollbar-track {
  
    border-radius: 4px;
    background-color: white;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: lightgray; 
    border-radius: 4px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: gray; 
  }
  .kanboard_child{
    width: 240px;
  }
  
  .kanboard_child.hide_board{
    width: 30px;
    overflow: hidden;
   
  }
  .kanboard_header{
    background-color: #f8f9fa;
    border-radius: 6px;
    padding: 10px ;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .hide_board  .kanboard_header{
    justify-content: center;
  }
  
  .hide_board  .board_header_left{
    display: none;
  }
  .kanboard_view{
    display: none;
   
  }
  .card_icon{
    margin: 0;
    cursor: pointer;
  }
  .card_icon i{
    font-size: 26x;
  }
  .kanboard_close{
    display: block;
  
  }
  .hide_board .kanboard_close{
    display: none!important;
   
  }
  .hide_board .kanboard_view{
    display: block!important;
  
  }
  .kanboard_body{
    background-color: #f8f9fa;
    border-radius: 6px;
    padding: 15px ;
    display: flex;
    align-items: center;
    flex-direction: column;
  
    gap:10px;
    width: 100%;
    margin-top: 10px;
    max-height: calc(100vh - 230px);
    overflow-x: hidden;
    overflow-y: auto;
  
  }
  .kanboard_body::-webkit-scrollbar {
    width: 8px;
  }
  .hide_board .kanboard_card{
    display:none;
  }
  .board_name{
    display: none;
    padding: 15px;
  }
  .hide_board .board_name{
    display: block;
    transform: rotate(-90deg);
    white-space: nowrap;
  }
  .flex_align{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .kanboard_card{
    background-color: white;
    border-radius: 6px;
    padding: 10px;
    border: 1px solid lightgray;
    border-left: 3px solid #ffd600;
    display: flex;
    flex-direction: column;
    gap:5px;
    width: 210px;
  }
  .kanboard_card h5{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  
  .kanboard_card p{
    // white-space:initial;
    white-space: nowrap;
      text-overflow: ellipsis; 
      overflow: hidden; 
  }
  .board_header_left{
    display: flex;
    align-items: center;
    gap: 6px;
  }
  .board_header_left img{
    width: 18px;
    height: 18px;
    object-fit: cover;
    border-radius: 4px;
  }
  .icon_eye{
    color: lightgray;
  }
  
  .kanbancard-empty{
    background-color: unset !important;
    border: unset !important;
    height: 200px !important;
  }