#working {
    color: #01a31f !important;
}

#completed {
    color: #0065bd !important;
}

#archived {
    color: #ff0000 !important;
}