.refresh {
  background: #def4d7;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  padding: 5px;
  border: 0;
  border-radius: 4px;
  width: 52px;
  /* height: 35px; */
  margin: 0 auto;
  display: block;
}
.reset {
  background: #f4d7d7;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  padding: 5px;
  border: 0;
  border-radius: 4px;
  width: 52px;
  margin-top: 0.6rem;
  height: 35px;
  margin: 0 auto;
  display: block;
}
.icon {
  fill: #323232;
}
.refresh:hover {
  background: #ffffff;
  transition: ease 0.5s;
  cursor: pointer;
}
.reset:hover {
  background: #ffffff;
  transition: ease 0.5s;

  cursor: pointer;
}

.loading {
  background: #ffffff;
  transition: ease 0.5s;
  cursor: wait;
}

.loading .icon {
    fill: #5ac938;
    -webkit-animation: rotating 1.2s linear infinite;
    -moz-animation: rotating 1.2s linear infinite;
    -ms-animation: rotating 1.2s linear infinite;
    -o-animation: rotating 1.2s linear infinite;
    animation: rotating 1.2s linear infinite;
  }


@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}