#tab-1 {
    padding: inherit;
}
#pg-kanban {
    padding-top: 5rem;
}
.Kanban-Search {
    float: right;
    border-radius: 16px;
    box-sizing: border-box;
    border-style: solid;
    width: 13%;
    border-width: 1px;
    height: 32px;
    padding: 0 16px 0 32px;
}
.kanban-them {
    float: right;
}
.kanban-them-btn {
    color: #f06a6a;
    margin-top: -25%;
}
#main-tabs {
    font-family: IBM Plex Sans, sans-serif;
}
table.dataTable thead > tr > th.sorting_asc,
table.dataTable thead > tr > th.sorting_desc,
table.dataTable thead > tr > th.sorting,
table.dataTable thead > tr > td.sorting_asc,
table.dataTable thead > tr > td.sorting_desc,
table.dataTable thead > tr > td.sorting {
    padding-right: 30px;
}
table.dataTable thead > tr > th:active,
table.dataTable thead > tr > td:active {
    outline: none;
}
table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
    cursor: pointer;
    position: relative;
}
table.dataTable thead .sorting:before,
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:before,
table.dataTable thead .sorting_desc_disabled:after {
    position: absolute;
    bottom: 0.9em;
    display: block;
    opacity: 0.3;
}
table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:before {
    right: 1em;
    content: "\2191";
}
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
    right: 0.5em;
    content: "\2193";
}
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:after {
    opacity: 1;
}
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:after {
    opacity: 0;
}
div.dataTables_scrollHead table.dataTable {
    margin-bottom: 0 !important;
}
div.dataTables_scrollBody table {
    border-top: none;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}
div.dataTables_scrollBody table thead .sorting:before,
div.dataTables_scrollBody table thead .sorting_asc:before,
div.dataTables_scrollBody table thead .sorting_desc:before,
div.dataTables_scrollBody table thead .sorting:after,
div.dataTables_scrollBody table thead .sorting_asc:after,
div.dataTables_scrollBody table thead .sorting_desc:after {
    display: none;
}
div.dataTables_scrollBody table tbody tr:first-child th,
div.dataTables_scrollBody table tbody tr:first-child td {
    border-top: none;
}
div.dataTables_scrollFoot > .dataTables_scrollFootInner {
    box-sizing: content-box;
}
div.dataTables_scrollFoot > .dataTables_scrollFootInner > table {
    margin-top: 0 !important;
    border-top: none;
}
@media screen and (max-width: 767px) {
    div.dataTables_wrapper div.dataTables_length,
    div.dataTables_wrapper div.dataTables_filter,
    div.dataTables_wrapper div.dataTables_info,
    div.dataTables_wrapper div.dataTables_paginate {
        text-align: center;
    }
}
table.dataTable.table-sm > thead > tr > th {
    padding-right: 20px;
}
table.dataTable.table-sm .sorting:before,
table.dataTable.table-sm .sorting_asc:before,
table.dataTable.table-sm .sorting_desc:before {
    top: 5px;
    right: 0.85em;
}
table.dataTable.table-sm .sorting:after,
table.dataTable.table-sm .sorting_asc:after,
table.dataTable.table-sm .sorting_desc:after {
    top: 5px;
}
table.table-bordered.dataTable th,
table.table-bordered.dataTable td {
    border-left-width: 0;
}
table.table-bordered.dataTable th:last-child,
table.table-bordered.dataTable th:last-child,
table.table-bordered.dataTable td:last-child,
table.table-bordered.dataTable td:last-child {
    border-right-width: 0;
}
table.table-bordered.dataTable tbody th,
table.table-bordered.dataTable tbody td {
    border-bottom-width: 0;
}
div.dataTables_scrollHead table.table-bordered {
    border-bottom-width: 0;
}
div.table-responsive > div.dataTables_wrapper > div.row {
    margin: 0;
}
div.table-responsive > div.dataTables_wrapper > div.row > div[class^="col-"]:first-child {
    padding-left: 0;
}
div.table-responsive > div.dataTables_wrapper > div.row > div[class^="col-"]:last-child {
    padding-right: 0;
}

#projectDashboardTableId th {
    text-align: center;
}

#projectDashboardTableId td:nth-child(1),
#projectDashboardTableId td:nth-child(5) {
    text-align: center;
}
#projectDashboardTableId td:nth-child(1){
    width: 90px;
}
#projectDashboardTableId td:nth-child(2){
    width: 100px;
}
#projectDashboardTableId td:nth-child(3){
    width: 200px;
}
#projectDashboardTableId td:nth-child(4),
#projectDashboardTableId td:nth-child(5){
    width: 50px;
}
.pagination {
    position: relative;
    z-index: 0;
}
#qaDataTableID th {
    text-align: center;
}
#qaDataTableID td:nth-child(1),
#qaDataTableID td:nth-child(6),
#qaDataTableID td:nth-child(2){
    text-align: center;
}
#qaDataTableID td:nth-child(1){
    width: 1px;
}
#qaDataTableID td:nth-child(2){
    width: 100px;
}
#qaDataTableID td:nth-child(6),
#qaDataTableID td:nth-child(5){
    width: 75px;
}
#taskListTable > :not(caption) > * > *{
    padding: 4px;
}
#taskListTable th{
    padding: 8px;
}
#taskListTable td .form-control{
    height: 30px !important;
}
#taskListTable td .select2-selection__control {
    height: 30px !important;
    min-height: 30px !important;
}
#taskListTable td .select2-selection__indicator {
    padding: 5px !important;
}
.MuiTabs-scroller{
    margin: -18px !important;
}
.MuiTabs-root{
    min-height: 30px !important;
}
.MuiTabs-indicator{
    margin: auto !important;
    bottom: 18px !important;
}
#allTaskListTables th{
    text-align: center;
}
#projectInfoTable td{
    border-top:none;
    border-bottom: none;
}
#flowCountTableId td:last-child{
    text-align: left !important;
}
#flowCountTableId tr:last-child td:nth-child(1){
    border-right: white thin solid !important;
}
#allTaskListTables td, #allTaskListTables th{
    padding: 7px !important;
}
#allTaskListTables .chat-icon i{
    top: 17px;
    position: absolute;
    right: 11px;
  }
#allTaskListTables  .chat-icon .badge {
    position: absolute;
    top: 8px;
    right: 0px;
    background: red;
    color: white;
    font-size: 8px;
  }