#leadsTableID th {
    text-align: center;
}

#leadsTableID td:nth-child(1),
#leadsTableID td:nth-child(2),
#leadsTableID td:nth-child(5),
#leadsTableID td:nth-child(8) {
    text-align: center;
}

#leadsTableID td:nth-child(2) {
    width: 95px;
}

#leadsTableID td:nth-child(1) {
    width: 5px;
}

#leadsTableID td:nth-child(7) {
    width: 95px;
}

#leadsTableID td:nth-child(4),
#leadsTableID td:nth-child(8) {
    width: 100px;
}

#enquiryModalTable {
    width: 100%
}

.badge-enq-category {
    margin-right: 5px;
    font-size: 11px !important;
}

#followupModalTable th {
    text-align: center;
}

#followupModalTable td:nth-child(1) {
    text-align: center;
    width: 50px
}

#followupModalTable td:nth-child(2) {
    text-align: center;
    width: 95px
}

#followupModalTable td:nth-child(3) {
    text-align: center;
    width: 129px
}

#followupModalTable td:nth-child(4) {
    width: 150px
}

#followupModalTable td:nth-child(6) {
    width: 100px;
    text-align: center;
}

#followupModalTable td:nth-child(7) {
    width: 150px;
    text-align: left;
}

#reg_name .select2-selection__indicators,
#reg_mobile .select2-selection__indicators {
    display: none;
}