#tasktable td:nth-child(1),
#tasktable td:nth-child(2),
#tasktable td:nth-child(3),
#tasktable td:nth-child(4){
  text-align: center;
}
#tasktable th{
    text-align: center;
} 
#tasktable td:last-child{
  text-align: left;
} 