.tv-page-content{
    padding: 0px !important;
    padding-top: 10px !important;
}
#AssignlistTableId th{
    text-align: center;
}
#AssignlistTableId td:nth-child(2){
    width:120px
}
#AssignlistTableId td:nth-child(3){
    width:120px
}
#AssignlistTableId td:nth-child(4),
#AssignlistTableId td:nth-child(5){
    width:90px
}
#AssignlistTableId td:nth-child(6){
    width:100px
}
// .h4-title{
//     font-size: 35px;
// }
// .h4-subtitle{
//     font-size: 17px;
// }
// .h4-para{
//     font-size: 15px;
// }

#TVstaffDashboardProfile td:nth-child(1),
#TVstaffDashboardProfile td:nth-child(3){
    width: 45px;
}

#StaffAssignlistTableId th{
    text-align: center;
}
#StaffAssignlistTableId td:nth-child(1){
    width:100px
}
#StaffAssignlistTableId td:nth-child(4),
#StaffAssignlistTableId td:nth-child(5){
    width:90px
}
#StaffAssignlistTableId td:nth-child(6){
    width:100px
}

#tableBadges td{
    padding:2px;
    width:103px
  }
  
.badge-col{
    margin-top: 10px;
}
.badge-image{
    width: 32px;
}
.badge-percenatge{
    font-size: 19px;
    font-weight: bolder;
}
.event-image{
    height:500px;
    border: white 10px solid;
    box-shadow: rgba(0, 0, 0,  0.35) 0px 5px 15px;

}