#taxTableIdd th{text-align: center;}
#taxTableId td:nth-child(1),
#taxTableId td:nth-child(2),
#taxTableId td:nth-child(3){
    text-align: center;
}


#taxTableId td:nth-child(5),
#taxTableId td:nth-child(7),
#taxTableId td:nth-child(6),
#taxTableId td:nth-child(4){
    text-align: left;
}