.activity-screenshot {
    font-size: 11px;
    padding-top: 6px;
    text-align: center;
}

.label-project-name {
    display: inline-block;
    border-radius: 20px;
    background-color: #eff1f5;
    padding: 3px 12px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #3e4956;
    font-size: 13px;
    font-weight: 500;
    min-height: 24px;
}

.top-shot-body,
.top-shot-body-no-hover {
    border-radius: 3px;
    overflow: hidden
}

.shot-body {
    cursor: pointer;
    position: relative;
    overflow: hidden;
    padding-bottom: 56.17%
}

.shot-body {
    cursor: pointer;
    position: relative;
    overflow: hidden;
    padding-bottom: 56.17%
}

.shot-body.no-screenshot {
    cursor: auto
}

.shot-body.no-screenshot:before {
    background-color: transparent
}

.shot-body:hover:before,
.shot-body.popover-visible:before {
    opacity: 1
}

.shot-body:hover .activity-actions,
.shot-body:hover .overlay-content,
.shot-body.popover-visible .activity-actions,
.shot-body.popover-visible .overlay-content {
    opacity: 1 !important
}

.shot-body:hover .screenshot-activity-overlay,
.shot-body.popover-visible .screenshot-activity-overlay {
    -moz-transform: translateY(0);
    -o-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0)
}

.shot-body:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.65);
    top: 0;
    left: 0;
    z-index: 0;
    opacity: 0;
    -webkit-transition: opacity 0.5s ease 0s;
    -o-transition: opacity 0.5s ease 0s;
    transition: opacity 0.5s ease 0s
}

.shot-body .screenshot-activity-overlay {
    position: absolute;
    // z-index: 3;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    -webkit-transition: transform 0.2s ease 0s;
    -o-transition: transform 0.2s ease 0s;
    transition: transform 0.2s ease 0s;
    -moz-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%)
}

.shot-body .screenshot-activity-overlay .activity-actions,
.shot-body .screenshot-activity-overlay .overlay-content {
    opacity: 0;
    -webkit-transition: opacity 0.5s ease 0s;
    -o-transition: opacity 0.5s ease 0s;
    transition: opacity 0.5s ease 0s
}

.shot-body .screenshot-activity-overlay .activity-actions {
    display: flex;
    align-items: center
}

.shot-body .screenshot-activity-overlay .activity-actions>a,
.shot-body .screenshot-activity-overlay .activity-actions>span {
    display: inline-block;
    width: 25px;
    text-align: center
}

.shot-body .screenshot-activity-overlay .slot-view {
    margin: auto;
    transform: translateY(-15px)
}

.shot-body .screenshot-activity-overlay .slot-label {
    position: absolute;
    top: 75%;
    text-align: center;
    width: 100%;
    color: white
}

.shot-body .screenshot-activity-overlay .btn-outline-default {
    background-color: #fff;
    color: #2b343f
}

@media (min-width: 1200px) and (max-width: 1300px) {
    .shot-body .screenshot-activity-overlay .btn-outline-default {
        background-color: transparent;
        border: none;
        color: #fff
    }
}

.shot-body .screenshot-activity-overlay .select-action .rounded-checkbox-wrapper label {
    margin-bottom: 0
}

.shot-body .screenshot-activity-overlay .activity-actions-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px
}

.shot-body.no-actions .screenshot-activity-overlay .slot-view {
    transform: translateY(0)
}

.notransition {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -o-transition: none !important;
    transition: none !important
}

.state_shot {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: #e9edef;
    color: #7a8798;
    font-size: 12px;
    text-align: center
}

.state_shot img {
    width: 100%
}

.state_shot .hi,
.state_shot .toggling-dropdown>li.is-on>a:before,
.state_shot .rounded-checkbox-wrapper input:checked+label:before,
.rounded-checkbox-wrapper .state_shot input:checked+label:before,
.state_shot .select2-container--bootstrap.select2-container--bootstrap .select2-selection__choice__remove,
.select2-container--bootstrap.select2-container--bootstrap .state_shot .select2-selection__choice__remove,
.state_shot .datepicker:before,
.state_shot .hs_daterange_picker:before,
.state_shot .search-filter:before,
.state_shot .groupBy-select-container .select2-container--bootstrap.select2-container--bootstrap .select2-selection:before,
.groupBy-select-container .select2-container--bootstrap.select2-container--bootstrap .state_shot .select2-selection:before,
.state_shot .groupBy-select-container .select2-container--bootstrap.select2-container--bootstrap .select2-selection__arrow b:before,
.groupBy-select-container .select2-container--bootstrap.select2-container--bootstrap .select2-selection__arrow .state_shot b:before,
.state_shot .groupBy-select-container .select2-container--bootstrap.select2-container--bootstrap .select2-results__option.is-date:before,
.groupBy-select-container .select2-container--bootstrap.select2-container--bootstrap .state_shot .select2-results__option.is-date:before,
.state_shot .groupBy-select-container .select2-container--bootstrap.select2-container--bootstrap .select2-results__option.is-week:before,
.groupBy-select-container .select2-container--bootstrap.select2-container--bootstrap .state_shot .select2-results__option.is-week:before,
.state_shot .groupBy-select-container .select2-container--bootstrap.select2-container--bootstrap .select2-results__option.is-paid:before,
.groupBy-select-container .select2-container--bootstrap.select2-container--bootstrap .state_shot .select2-results__option.is-paid:before,
.state_shot .groupBy-select-container .select2-container--bootstrap.select2-container--bootstrap .select2-results__option.is-user:before,
.groupBy-select-container .select2-container--bootstrap.select2-container--bootstrap .state_shot .select2-results__option.is-user:before,
.state_shot .groupBy-select-container .select2-container--bootstrap.select2-container--bootstrap .select2-results__option.is-member:before,
.groupBy-select-container .select2-container--bootstrap.select2-container--bootstrap .state_shot .select2-results__option.is-member:before,
.state_shot .groupBy-select-container .select2-container--bootstrap.select2-container--bootstrap .select2-results__option.is-client:before,
.groupBy-select-container .select2-container--bootstrap.select2-container--bootstrap .state_shot .select2-results__option.is-client:before,
.state_shot .groupBy-select-container .select2-container--bootstrap.select2-container--bootstrap .select2-results__option.is-project:before,
.groupBy-select-container .select2-container--bootstrap.select2-container--bootstrap .state_shot .select2-results__option.is-project:before,
.state_shot .groupBy-select-container .select2-container--bootstrap.select2-container--bootstrap .select2-results__option.is-timesheet:before,
.groupBy-select-container .select2-container--bootstrap.select2-container--bootstrap .state_shot .select2-results__option.is-timesheet:before,
.state_shot .groupBy-select-container .select2-container--bootstrap.select2-container--bootstrap .select2-results__option.is-task:before,
.groupBy-select-container .select2-container--bootstrap.select2-container--bootstrap .state_shot .select2-results__option.is-task:before,
.state_shot .groupBy-select-container .select2-container--bootstrap.select2-container--bootstrap .select2-results__option.is-job_site:before,
.groupBy-select-container .select2-container--bootstrap.select2-container--bootstrap .state_shot .select2-results__option.is-job_site:before,
.state_shot .groupBy-select-container .select2-container--bootstrap.select2-container--bootstrap .select2-results__option.is-work_break_policy:before,
.groupBy-select-container .select2-container--bootstrap.select2-container--bootstrap .state_shot .select2-results__option.is-work_break_policy:before,
.state_shot .vue-map-search:before,
.state_shot .is-first.is-last.is-complete .v-step-number-wrapper .step-number,
.is-first.is-last.is-complete .v-step-number-wrapper .state_shot .step-number {
    color: #aab2ba;
    font-size: 32px;
    margin-bottom: 2px
}

.state_shot .activity-img {
    width: 100%
}

.state_shot .state-shot-wrapper {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.screen-count-pill {
    background: #fff;
    border-radius: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.19);
    color: #2aa7ff;
    font-size: 12px;
    font-weight: bold;
    left: 0;
    margin: -18px auto 0;
    padding: 5px 15px;
    pointer-events: none;
    position: absolute;
    right: 0;
    width: 87px;
    z-index: 1
}

.meta {
    border: 1px solid #e4e9ef;
    border-top: none;
    border-radius: 0 0 3px 3px;
    padding: 15px 10px
}

.meta-head {
    display: flex;
    flex-wrap: wrap;
    justify-content: center
}

.meta-interval {
    text-align: left;
    font-size: 12px;
    color: #3e4956;
    margin-right: 10px
}

.time {
    padding-right: 0;
    text-align: left;
    color: #3e4956;
    font-size: 12px
}

.custtimeline {
    border-left: 2px solid #e4e8ea !important;
}

.hr-row {
    border-bottom: 1px solid #a6acb2;
    padding-top: 10px;
}

#SliderModal>.modal-content {
    width: 938px;
}

.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    left: 50%;
    margin-left: -60px;
}

.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}