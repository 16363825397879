// table.dataTable thead > tr > th.sorting_asc,
// table.dataTable thead > tr > th.sorting_desc,
// table.dataTable thead > tr > th.sorting,
// table.dataTable thead > tr > td.sorting_asc,
// table.dataTable thead > tr > td.sorting_desc,
// table.dataTable thead > tr > td.sorting {
//   padding-right: 30px;
// }
// table.dataTable thead > tr > th:active,
// table.dataTable thead > tr > td:active {
//   outline: none;
// }
// table.dataTable thead .sorting,
// table.dataTable thead .sorting_asc,
// table.dataTable thead .sorting_desc,
// table.dataTable thead .sorting_asc_disabled,
// table.dataTable thead .sorting_desc_disabled {
//   cursor: pointer;
//   position: relative;
// }
// table.dataTable thead .sorting:before,
// table.dataTable thead .sorting:after,
// table.dataTable thead .sorting_asc:before,
// table.dataTable thead .sorting_asc:after,
// table.dataTable thead .sorting_desc:before,
// table.dataTable thead .sorting_desc:after,
// table.dataTable thead .sorting_asc_disabled:before,
// table.dataTable thead .sorting_asc_disabled:after,
// table.dataTable thead .sorting_desc_disabled:before,
// table.dataTable thead .sorting_desc_disabled:after {
//   position: absolute;
//   bottom: 0.9em;
//   display: block;
//   opacity: 0.3;
// }
// table.dataTable thead .sorting:before,
// table.dataTable thead .sorting_asc:before,
// table.dataTable thead .sorting_desc:before,
// table.dataTable thead .sorting_asc_disabled:before,
// table.dataTable thead .sorting_desc_disabled:before {
//   right: 1em;
//   content: "\2191";
// }
// table.dataTable thead .sorting:after,
// table.dataTable thead .sorting_asc:after,
// table.dataTable thead .sorting_desc:after,
// table.dataTable thead .sorting_asc_disabled:after,
// table.dataTable thead .sorting_desc_disabled:after {
//   right: 0.5em;
//   content: "\2193";
// }
// table.dataTable thead .sorting_asc:before,
// table.dataTable thead .sorting_desc:after {
//   opacity: 1;
// }
// table.dataTable thead .sorting_asc_disabled:before,
// table.dataTable thead .sorting_desc_disabled:after {
//   opacity: 0;
// }
// div.dataTables_scrollHead table.dataTable {
//   margin-bottom: 0 !important;
// }
// div.dataTables_scrollBody table {
//   border-top: none;
//   margin-top: 0 !important;
//   margin-bottom: 0 !important;
// }
// div.dataTables_scrollBody table thead .sorting:before,
// div.dataTables_scrollBody table thead .sorting_asc:before,
// div.dataTables_scrollBody table thead .sorting_desc:before,
// div.dataTables_scrollBody table thead .sorting:after,
// div.dataTables_scrollBody table thead .sorting_asc:after,
// div.dataTables_scrollBody table thead .sorting_desc:after {
//   display: none;
// }
// div.dataTables_scrollBody table tbody tr:first-child th,
// div.dataTables_scrollBody table tbody tr:first-child td {
//   border-top: none;
// }
// div.dataTables_scrollFoot > .dataTables_scrollFootInner {
//   box-sizing: content-box;
// }
// div.dataTables_scrollFoot > .dataTables_scrollFootInner > table {
//   margin-top: 0 !important;
//   border-top: none;
// }
// @media screen and (max-width: 767px) {
//   div.dataTables_wrapper div.dataTables_length,
//   div.dataTables_wrapper div.dataTables_filter,
//   div.dataTables_wrapper div.dataTables_info,
//   div.dataTables_wrapper div.dataTables_paginate {
//     text-align: center;
//   }
// }
// table.dataTable.table-sm > thead > tr > th {
//   padding-right: 20px;
// }
// table.dataTable.table-sm .sorting:before,
// table.dataTable.table-sm .sorting_asc:before,
// table.dataTable.table-sm .sorting_desc:before {
//   top: 5px;
//   right: 0.85em;
// }
// table.dataTable.table-sm .sorting:after,
// table.dataTable.table-sm .sorting_asc:after,
// table.dataTable.table-sm .sorting_desc:after {
//   top: 5px;
// }
// table.table-bordered.dataTable th,
// table.table-bordered.dataTable td {
//   border-left-width: 0;
// }
// table.table-bordered.dataTable th:last-child,
// table.table-bordered.dataTable th:last-child,
// table.table-bordered.dataTable td:last-child,
// table.table-bordered.dataTable td:last-child {
//   border-right-width: 0;
// }
// table.table-bordered.dataTable tbody th,
// table.table-bordered.dataTable tbody td {
//   border-bottom-width: 0;
// }
// div.dataTables_scrollHead table.table-bordered {
//   border-bottom-width: 0;
// }
// div.table-responsive > div.dataTables_wrapper > div.row {
//   margin: 0;
// }
// div.table-responsive
//   > div.dataTables_wrapper
//   > div.row
//   > div[class^="col-"]:first-child {
//   padding-left: 0;
// }
// div.table-responsive
//   > div.dataTables_wrapper
//   > div.row
//   > div[class^="col-"]:last-child {
//   padding-right: 0;
// }

// #companyTableId th{
//   text-align: center;
// }
// #companyTableId td:nth-child(1),
// #companyTableId td:nth-child(5){
//   text-align: center;
// }

.row {
    --bs-gutter-x: 1.25rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(var(--bs-gutter-y) * -1);
    margin-right: calc(var(--bs-gutter-x) * -0.5);
    margin-left: calc(var(--bs-gutter-x) * -0.5);
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid #f6f6f6;
    border-radius: 0.25rem;
    margin-bottom: 1.25rem;
}
.body {
    margin: 0;
    font-family: IBM Plex Sans, sans-serif;
    font-family: var(--bs-body-font-family);
    font-size: 0.9rem;
    font-size: var(--bs-body-font-size);
    font-weight: 400;
    font-weight: var(--bs-body-font-weight);
    line-height: 1.5;
    line-height: var(--bs-body-line-height);
    color: #495057;
    color: var(--bs-body-color);
    text-align: var(--bs-body-text-align);
    background-color: #f5f6f8;
    background-color: var(--bs-body-bg);
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

h5 {
    font-size: 1.125rem;
    color: #495057;
}
p {
    margin-top: 0;
    margin-bottom: 1rem;
    box-sizing: border-box;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
   // color:#196fb2 !important
    color: #74788d !important;
}
.card-body {
    flex: 1 1 auto;
    padding: 1.25rem;
}

@media (min-width: 1200px) {
    .col-xl-4 {
        flex: 0 0 auto;
        width: 33.33333%;
    }
}

@media (min-width: 1200px) {
    .col-xl-8 {
        flex: 0 0 auto;
        width: 66.66667%;
    }
}
#customerTableId th {
    text-align: center;
}
#customerTableId td:nth-child(1),
#customerTableId td:nth-child(2),
#customerTableId td:nth-child(4) {
    text-align: center;
}
#customerTableId td:nth-child(6),
#customerTableId td:nth-child(7) {
    text-align: right;
}
.pagination {
    position: relative;
    z-index: 0;
}
