
#categoryTableId th {
  text-align: center;
}

#categoryTableId td:nth-child(1),
#categoryTableId td:nth-child(2),
#categoryTableId td:nth-child(3),
#categoryTableId td:nth-child(7) {
  text-align: center;
}

.pagination {
  position: relative;
  z-index: 0;
}
#categoryTableId td:nth-child(1){
  width: 97px;
}
#categoryTableId td:nth-child(2),
#categoryTableId td:nth-child(3){
  width: 100px;
}
#categoryTableId td:nth-child(7){
  width: 80px;
}
#categoryTableId td:nth-child(5){
  width: 200px;
}
#categoryTableId td:nth-child(6){
  width: 150px;
}

