#activityTimeReport th,
#activityTimeReport td:nth-child(3),
#activityTimeReport td:nth-child(4),
#activityTimeReport td:nth-child(5),
#activityTimeReport td:nth-child(6),
#activityTimeReport td:nth-child(7) {
    text-align: center;
}

/** pagination */
.pagination-div {
    justify-content: end;
    display: flex;
}

.Mui-selected {
    /* background: #dee3fb; */
    color: #fff !important;
    background-color: #5b73e8 !important;
    border-color: #5b73e8 !important;
}

.MuiButtonBase-root {
    min-width: 30px !important;
    height: 30px !important;
    border-radius: 0px !important;
}

.highlight-row {
    background: #78d7b6;
    font-size: 12px !important;
    /* Example color for highlighted rows */
}