#CaseTableId td:nth-child(2),
#CaseTableId td:nth-child(3),
#CaseTableId td:nth-child(4),
#CaseTableId td:nth-child(5),
#CaseTableId td:nth-child(6),
#CaseTableId td:nth-child(7){
    text-align: left;
}
#CaseTableId td:nth-child(1){
    text-align: center;
}
#CaseTableId th:nth-child(6){
    text-align: center;
}
#CaseTableId td:nth-child(1){
    width: 3px;
}
#casePopupTableId td:nth-child(1){
    text-align: center;
}
.upload_image_close{
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    right:0px;
    top: 5px;
    text-align: center;
    line-height: 24px;
    z-index: 1;
    cursor: pointer;
    border:none;
    margin-top: 10px;
  }
  .upload_image_close:after {
    content: "✖";
    font-size: 14px;
    color: white;
  }