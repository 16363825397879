.table.dataTable th,
.table.dataTable td {
  background-color: #fff;
}

.popover {
  position: absolute;
  will-change: transform;
  top: 0px;
  left: 0px;
  margin-left: 20px !important;
  transform: translate3d(494px, 411px, 0px);
  width: 200px;
  font-size: 12px;
}

.reported_tasks_list .popover {
  position: absolute;
  will-change: transform;
  top: 0px;
  left: 0px;
  margin-left: 200px !important;
  transform: translate3d(494px, 411px, 0px);
  width: 200px;
  font-size: 12px;
}

.arrow {
  top: 18px;
  position: fixed;
  margin-left: -13px;
  border-right: solid 10px #201e1e2e;
  border-bottom: solid 10px transparent;
  border-top: solid 10px transparent;
}

#myTaskHomePopTable > :not(caption) > * > * {
  border-bottom-width: 0px !important;
  border-top-width: 0px !important;
}

#myTaskHomePopTable {
  font-size: 12px;
  font-weight: bold;
}

.homeTaskLink {
  color: #5b88ed;
  //font-weight: 500;
  cursor: pointer;
  border: none;
  background-color: white;
  text-align: left;
}

.playButton,
.pauseButton {
  cursor: pointer;
}

.scrollbar-container {
  height: calc(100vh - 125px) !important;
}

.image-upload > input {
  display: none;
}

.fa-paperclip {
  font-size: 20px;
  padding-top: 5px;
}

.chat-fa-icon {
  font-size: 80px;
}

.remarks-comment {
  white-space: pre-line;
  text-align: left;
}

.chat-input {
  height: 40px !important;
}

#myTaskTable td,
#myTaskTable th {
  padding: 7px !important;
}

#partialTasksTableID td:last-child {
  text-align: left;
}

.chat-icon {
  top: -10px;
  position: relative;
}

.chat-icon i {
  top: 17px;
  position: absolute;
  right: 3px;
}

.chat-icon .badge {
  position: absolute;
  top: 8px;
  right: -5px;
  background: red;
  color: white;
  font-size: 8px;
}

.chat-input-section {
  border-top: none !important;
}

#tableBadges td {
  padding: 2px;
  width: 103px;
}

.to-do-ul {
  padding-left: 0 !important;
}
.to-do-ul li {
  list-style: none;
}

.button-to-do {
  padding: 4px 10px !important;
}

.cancel-icon {
  display: none; /* Hidden by default */
  margin-left: auto; /* Push it to the right side */
  cursor: pointer;
}

li:hover .cancel-icon {
  display: inline; /* Show on hover */
}

.cancel-icon {
  border-radius: 50%;
}

.to-do-close {
  font-size: small;
}
.icon-to-do-close {
  border-radius: 50% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #edecec;
  padding: 3px;
}
.to-do-text-close {
  cursor: pointer;
}

.span-assin {
  color: #1a71b5;
}
.close-none {
  display: none;
}

.view-all {
  font-size: smaller;
  color: #1a71b5;
  cursor: pointer;
}

.fetch-names-div {
  box-shadow: 0 2px 4px rgba(15, 34, 58, 0.12);
  padding: 10px;
  // height: 200px;
  overflow-x: auto;
  max-height: 200px;
}

.select-email-p {
  cursor: pointer;
  padding: 6px 10px;
  margin-bottom: 0;
  font-weight: 400;
  margin-bottom: 10px;
  transition: 0.3s;
}

.select-email-p:hover {
  cursor: pointer;
  padding: 6px 10px;
  margin-bottom: 10px;
  background: #1a71b526;
  border-radius: 0.3rem;
  color: #1a71b5 !important;
  font-weight: 400;
}

.active-email {
  cursor: pointer;
  padding: 6px 10px;
  margin-bottom: 10px;
  background: #1a71b526;
  border-radius: 0.3rem;
  color: #1a71b5 !important;
  font-weight: 400;
}

.fiter-5-sub{
  display: flex;
  flex-flow: wrap;
  padding-left: 0px !important;
  list-style-type: none;
  margin: 0 -5px !important;
  cursor: pointer;
}
.fiter-5-sub li {
  flex: 1 0 calc(100% / 6);
    max-width: calc(100% / 6);
    display: flex;
    padding: 0 5px;
}
.status-a{
    padding: 8px;
    border-radius: 0.3rem;
    border: 1px solid #1a7ee0;
    font-size: 1rem;
    transition: .3s;
    width: 100%;
    display: flex;
    justify-content: center;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    color: #4c4c4c;
}
.status-a:hover{
  background-color: #1a7ee0;
  color: whitesmoke;
  transition: .3s;
}

.active-status-a{
  background: #1a7ee0 !important;
  color: whitesmoke;
}

.notmy-task{
  color: rgb(207, 115, 115);
}

#todotable th{
  text-align: center;
}
#todotable td:nth-child(1),
#todotable td:nth-child(2),
#todotable td:nth-child(3),
#todotable td:nth-child(7),
#todotable td:nth-child(8){
  text-align: center;
}
