
#TaskSubcategTableId th {
  text-align: center;
}
#TaskSubcategTableId td:nth-child(1),
#TaskSubcategTableId td:nth-child(7) {
  text-align: center;
}

.pagination {
  position: relative;
  z-index: 0;
}
#TaskSubcategTableId td:nth-child(1),
#TaskSubcategTableId td:nth-child(7){
  width: 95px;
}
#TaskSubcategTableId td:nth-child(4),
#TaskSubcategTableId td:nth-child(5),
#TaskSubcategTableId td:nth-child(6) {
  max-width: 120px;
}
